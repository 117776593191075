@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');

@layer base {
    body {
        @apply font-[Rubik];
    }
    li {
        @apply px-4;
        @apply py-1;
        @apply cursor-pointer;
    }
}

.content-div{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.content-div:hover {
    background-image: linear-gradient(
        to right,
        rgba(105, 218, 142, 0.699),
        hsla(152, 74%, 16%, 0.658)
    )!important;
}

.spline {
    height: 50%;
}